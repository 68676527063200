window.image_list=
  {
    
     "user_avatar" :"https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/avatars%2Fuser_avatar.jpg?alt=media&token=e7316c7b-d8c8-4f16-9389-c02c3f7da00c",
    "founder1": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/avatars%2Ffounder1.png?alt=media&token=33530331-f467-43f5-82df-17b08fdabe16",
    "founder2": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/avatars%2Ffounder2.png?alt=media&token=357f6352-e222-4fba-9f74-ca63a4ccb2c5",
    "founder3": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/avatars%2Ffounder3.png?alt=media&token=69e0fb7b-b2f0-4cb0-ade1-2d1d37747099",
    "founder4": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/avatars%2Ffounder4.png?alt=media&token=6d0d58c2-b7d7-4a5d-a3d5-fcf62af497f9",
    "founder5": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/avatars%2Ffounder5.png?alt=media&token=cf6829ad-862e-4bff-80b4-4fac1672ef5d",
    "customers_speaks_bg": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/backgrounds%2Fcustomers_speaks_bg.jpg?alt=media&token=4ba9dfa8-cded-48db-98dd-8005e4655e33",
    "how_it_works_bg": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/backgrounds%2Fhow_it_works_bg.jpg?alt=media&token=0414075b-e2be-40ac-867d-10ce011adc00",
    "404_footer": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/footer%2F404_footer.jpg?alt=media&token=c7aa3f8a-ff0b-4151-b62d-a3ccca74691a",
    "about_us_footer": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/footer%2Fabout_us_footer.jpg?alt=media&token=204e55ad-1fe6-4049-9693-06e661edb436",
    "blog_home_footer": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/footer%2Fblog_home_footer.jpg?alt=media&token=acc4081d-2757-4a72-94ff-dfc3735f0f62",
    "blog_post_footer": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/footer%2Fblog_post_footer.jpg?alt=media&token=140a9ac3-4d74-439a-8bff-7806a58a436b",
    "careers_footer": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/footer%2Fcareers_footer.jpg?alt=media&token=3f38e1b3-12cd-4dbb-a428-ee16fdab355e",
    "cart_footer": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/footer%2Fcart_footer.jpg?alt=media&token=4a3f64ff-ab2b-41ee-89a3-8a2073b9e917",
    "checkout_footer": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/footer%2Fcheckout_footer.jpg?alt=media&token=89ae7d09-23ec-4369-9dce-935b08ffc58f",
    "chef_application_footer": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/footer%2Fchef_application_footer.jpg?alt=media&token=587c316e-adc9-4884-a4a7-a9a8e7139397",
    "chef_dashboard_footer": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/footer%2Fchef_dashboard_footer.jpg?alt=media&token=a1ecb3f8-58b4-4080-9322-60c539969332",
    "chef_login_footer": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/footer%2Fchef_login_footer.jpg?alt=media&token=7933e85f-74c0-4aeb-88f2-b2718de91734",
    "chef_password_reset_footer": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/footer%2Fchef_password_reset_footer.jpg?alt=media&token=d4bfadd0-d48a-49fe-b47e-319130bf61ce",
    "chef_terms_footer": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/footer%2Fchef_terms_footer.jpg?alt=media&token=bdbfd47b-d3be-49bc-a0ee-465dbbcb4053",
    "contact_us_footer": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/footer%2Fcontact_us_footer.jpg?alt=media&token=bb50d51f-fe8e-41fa-a841-04f3b3108494",
    "faqs_footer": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/footer%2Ffaqs_footer.jpg?alt=media&token=80f7b2e1-4976-43e5-bab2-95d6938eb4cb",
    "home_footer": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/footer%2Fhome_footer.jpg?alt=media&token=9525e62c-5625-44b7-a72d-be78056c83d5",
    "how_it_works_footer": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/footer%2Fhow_it_works_footer.jpg?alt=media&token=49ceff41-e858-4667-b9e9-e32102b37c43",
    "menu_footer": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/footer%2Fmenu_footer.jpg?alt=media&token=7648c355-168a-4aaa-bd24-21d3c15d5d18",
    "my_account_footer": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/footer%2Fmy_account_footer.jpg?alt=media&token=d371c3a0-3e21-4483-bbbf-8e05cbbb1959",
    "my_orders_footer": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/footer%2Fmy_orders_footer.jpg?alt=media&token=6c54f488-0030-4fab-be0b-558a90675ec7",
    "order_confirmation_footer": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/footer%2Forder_confirmed_footer.jpg?alt=media&token=adb4da22-00e4-4b82-b020-4ffe86e574a2",
    "order_not_confirmed_footer": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/footer%2Forder_not_confirmed_footer.jpg?alt=media&token=d264cd49-9f81-4946-a149-fc9c85e2ac3d",
    "privacy_footer": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/footer%2Fprivacy_footer.jpg?alt=media&token=ae83ec24-26d3-454e-a7a9-64ed90f38b56",
    "terms_and_conditions_footer": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/footer%2Fterms_and_conditions_footer.jpg?alt=media&token=57d89001-a35e-41e6-b6c8-2057b7e5d39a",
    "user_password_reset_footer": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/footer%2Fuser_password_reset_footer.jpg?alt=media&token=91bf8ff8-f448-4c5e-aef2-6634db2adefe",
    "404_header": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/header%2F404_header.jpg?alt=media&token=322d6299-9e22-446e-9221-1708b321ccf1",
    "about_us_header": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/header%2Fabout_us_header.jpg?alt=media&token=291fdc89-08d5-4c08-8e0c-f15d3229b713",
    "blog_home_header": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/header%2Fblog_home_header.jpg?alt=media&token=5fb9c065-f0f7-4560-9071-16b7f528bb2c",
    "blog_post_header": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/header%2Fblog_post_header.jpg?alt=media&token=94c1d66b-2eed-435c-a39f-76236e19b7c6",
    "careers_header": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/header%2Fcareers_header.jpg?alt=media&token=ae07f4fa-6489-4e7b-89ed-c55f84e85822",
    "cart_header": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/header%2Fcart_header.jpg?alt=media&token=dd84271a-e2d9-44c7-9b4b-d1fe9eaef30b",
    "checkout_header": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/header%2Fcheckout_header.jpg?alt=media&token=d07df396-d879-4a9b-8e25-9d9748c68006",
    "chef_application_header": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/header%2Fchef_application_header.jpg?alt=media&token=0d1655a7-8ae3-43b9-956e-8811132f84c3",
    "chef_dashboard_header": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/header%2Fchef_dashboard_header.jpg?alt=media&token=14612057-6e36-4813-94b1-8594c0a11cc2",
    "chef_login_header": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/header%2Fchef_login_header.jpg?alt=media&token=15ae91d3-ad8b-47f5-b2c8-0d49b919fd33",
    "contact_us_header": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/header%2Fcontact_us_header.jpg?alt=media&token=53084ed9-6caa-4658-bb1e-aca6629d8edf",
    "faqs_header": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/header%2Ffaqs_header.jpg?alt=media&token=ef447f6e-eda2-47da-919b-a2f3e18c3368",
    "home_header": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/header%2Fhome_header.jpg?alt=media&token=437c24dc-80e8-4015-ae4c-6db2e81a6a4c",
    "how_it_works_header": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/header%2Fhow_it_works_header.jpg?alt=media&token=2a3fdd99-bd0a-4235-bc8d-9ab08dff7a2f",
    "menu_header": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/header%2Fmenu_header.jpg?alt=media&token=848e7111-d905-426e-8fd1-a4debff4ca24",
    "my_account_header": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/header%2Fmy_account_header.jpg?alt=media&token=cf47506b-35cb-41bc-966d-a5d70b322bc1",
    "my_orders_header": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/header%2Fmy_orders_header.jpg?alt=media&token=c30fb3bd-9ceb-4bd0-82e8-6040a33a165e",
    "orde_not_confirmed_header": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/header%2Forde_not_confirmed_header.jpg?alt=media&token=c2a82c4b-118e-4e6e-818a-7526d93eea94",
    "order_confirmation_header": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/header%2Forder_confirmed_header.jpg?alt=media&token=ebad7ed5-9c08-4937-b6e2-fe7ee085f0ad",
    "privacy_header": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/header%2Fprivacy_header.jpg?alt=media&token=29ba1eb3-1081-4820-b3c2-b0a00f478b4e",
    "terms_and_conditions_header": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/header%2Fterms_and_conditions_header.jpg?alt=media&token=31d42818-244a-4e33-8540-b76f954a0a12",
    "user_password_reset_header": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/header%2Fuser_password_reset_header.jpg?alt=media&token=5d77ae5e-d01c-43e0-85b1-a2d97ebda4a2",
    "hc_logo": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/HC_Logo.png?alt=media&token=6d6393ab-fd7f-4e43-87fc-bb284711bb60",
    "hc_logo_small": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/hc_logo_small.png?alt=media&token=a90c0696-7c7b-4596-8a43-3577443f2bd8",
    "loader": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/loader.gif?alt=media&token=c171faf2-a9f6-4410-b5aa-4abf3b50967f",
    "macbook_pro": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/macbook_pro.png?alt=media&token=9b01cb07-154c-4f11-8782-f27502fe1a70",
    "marker": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/marker.png?alt=media&token=daddd24d-9694-45dc-bd17-f12064b724d1",
    "benefits_icon1": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/icons%2Fbenefits_icon1.png?alt=media&token=0bfd27f7-e551-470b-a584-1772fae1429e",
    "benefits_icon2": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/icons%2Fbenefits_icon2.png?alt=media&token=25ac380e-8a61-4c83-8db0-5054640050c2",
    "benefits_icon3": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/icons%2Fbenefits_icon3.png?alt=media&token=f8e7ea83-e708-46cb-81b6-77f454e90b9d",
    "benefits_icon4": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/icons%2Fbenefits_icon4.png?alt=media&token=0d39b3de-b72a-4384-8e19-1fd430b3d507",
    "cooking_time": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/icons%2Fcooking_time.png?alt=media&token=52af3e0f-d418-4bd4-9371-18f0195776de",
    "diff_hard": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/icons%2Fdiff_difficult.png?alt=media&token=035cdab4-dcbc-4029-b7e1-148485e62a70",
    "non_veg_icon_32px": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/icons%2Fnon_veg_icon_32px.png?alt=media&token=861d7aa7-95fb-47ed-b27c-2d18b3d6a9d9",
    "rating": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/icons%2Frating.png?alt=media&token=53fdbd44-25a9-413d-aaf0-e1e7c27a2482",
    "diff_easy": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/icons%2Fdiff_easy.png?alt=media&token=10bfe77a-d592-47c7-9b60-42060bfb9e81",
    "diff_medium": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/icons%2Fdiff_medium.png?alt=media&token=a2f4e9c2-8db5-41fa-b599-2b69fdf182ff",
    "tomato1": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/icons%2Ftomato1.png?alt=media&token=44eb7082-1d3c-4dd3-8ef5-ec255f47abff",
    "tomato2": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/icons%2Ftomato1.png?alt=media&token=44eb7082-1d3c-4dd3-8ef5-ec255f47abff",
    "tomato3": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/icons%2Ftomato3.png?alt=media&token=3d5d12b1-aad4-49ea-94b2-4797048d8d52",
    "tomato4": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/icons%2Ftomato4.png?alt=media&token=7ddaff22-ecaf-4b3b-ac72-916e55dfeefa",
    "veg_icon_32px": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/icons%2Fveg_icon_32px.png?alt=media&token=0bce17fd-fd0c-4325-ba5f-769807fd5fd0",
    "buffer": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/mdl%2Fbuffer.svg?alt=media&token=d7ec4c8a-2a0a-4c2f-a10b-6cde9ffe6511",
    "tick": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/mdl%2Fbuffer.svg?alt=media&token=d7ec4c8a-2a0a-4c2f-a10b-6cde9ffe6511",
    "tick_mask": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/mdl%2Ftick_mask.svg?alt=media&token=00a18d3b-2e65-47b0-9298-6c1482615771",
    "how_it_works_step_1": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/sections%2Fhow_it_works_step_1.png?alt=media&token=339b391b-9e60-49cf-abbe-4eaa9a2063c4",
    "how_it_works_step_2": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/sections%2Fhow_it_works_step_2.png?alt=media&token=d58c2d11-a888-4f45-9a34-7b83cf58fd35",
    "how_it_works_step_3": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/sections%2Fhow_it_works_step_3.png?alt=media&token=d87e093f-b03d-4a1a-940a-1adf86d932bb",
    "how_it_works_step_4": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/sections%2Fhow_it_works_step_4.png?alt=media&token=4d103b34-5e5e-4989-af4a-1d85d090e4d8",
    "testi1": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/avatars%2Ftesti1.jpg?alt=media&token=4de2cd22-e732-49ed-aaa9-f39c7ac0b59c",
    "testi2": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/avatars%2Ftesti2.jpg?alt=media&token=fa87d3ea-10a3-4bb4-872a-76dcf441dece",
    "testi3": "https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/avatars%2Ftesti3.jpg?alt=media&token=b21826fa-6bac-42e7-a603-1e40829e5f69",
    "media_bg":"https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/sections%2Fmedia_bg.jpg?alt=media&token=138683fd-1776-4519-9661-5b11aaa1af16",
    "media_1":"https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/media%2Fmedia_icons1.png?alt=media&token=3ccb8db7-7c1d-4361-9858-46f6ddaebd17",
    "media_2":"https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/media%2Fmedia_icons2.png?alt=media&token=9ddc464c-33a2-4783-ac12-813ec0dece32",
    "media_3":"https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/media%2Fmedia_icons3.png?alt=media&token=eef02724-5879-4347-b607-f4ffd621feb3",
    "media_4":"https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/media%2Fmedia_icons4.png?alt=media&token=b91c8bb5-7161-4dfb-996d-159f05925283",
    "media_5":"https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/media%2Fmedia_icons5.png?alt=media&token=fc0dea10-86e2-4030-afea-ca858b1b5783",
    "media_6":"https://firebasestorage.googleapis.com/v0/b/project-5792600253261049261.appspot.com/o/media%2Fmedia_icons6.png?alt=media&token=da24fe8a-1eee-4720-8599-0501c0da1087",
  }
